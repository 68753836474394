import Marketing from 'containers/Marketing';
import useAuth from '../hooks/useAuth';

const IndexPage = () => {
    const { loggedIn } = useAuth();

    if (loggedIn) {
        return <div />;
    }

    return <Marketing />;
};

export default IndexPage;
